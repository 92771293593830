import React, { useEffect, useLayoutEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { analyticClickEvent } from "../../../components/googleAnalytics"
import { ReactAudioPlayer, ReactVideoPlayer } from "../../../components/reactPlayer"
import LayoutNoStripe from "../../../components/layoutNoStripe"
import {
  Section,
  SectionHeading,
} from "../../../styledComponents/section"
import { BackgroundColourBlock } from "../../../styledComponents/overView"
import {
  StyledVideoTestimonialArticle,
  StyledVideoTestimonialHeader,
  StyledVideoTestimonialVideo,
} from "../../../styledComponents/videoTestimonial"
import EventList from "../../../components/EventList"
import { StyledButton } from "../../../styledComponents/button"
import { EventListSection } from "../../../styledComponents/eventList"
import ProductList from "../../../components/ProductList"
import { StyledLinkButton } from "../../../styledComponents/button"

const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)

const FhtjMhgClassSessions = ({ data, location }) => {
  
  let products = data.allWpProduct.edges

  const [tabIndex, setTabIndex] = useState(0);

  const checkIfHasQuery = () => {
    const urlParams = new URLSearchParams(window.location.search)
    if(urlParams.has("session-1")){
      setTabIndex(0)
    }
    if(urlParams.has("session-2")){
      setTabIndex(1)
    }
    if(urlParams.has("session-3")){
      setTabIndex(2)
    }
    if(urlParams.has("session-4")){
      setTabIndex(3)
    }
  }

  useEffect(() => {
    checkIfHasQuery()
  }, [])

  const [isWatchFullVersionOpen, setIsWatchFullVersionOpen] = useState(false);
  const [isAutopPlay, setAutoPlay] = useState(false);

  const toggleTestimonials = (value) => {
    setIsWatchFullVersionOpen(value)
  }

  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      const urlParams = new URLSearchParams(window.location.search)
      const autoplay = urlParams.get('autoplay')
      setAutoPlay(autoplay)
      return
    }
  }, [])

  return (
    <> <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />x
    </Helmet>
      <LayoutNoStripe displayNavBorder location={location} hideNewsletterSignUp>
        <StyledVideoTestimonialArticle itemScope itemType="http://schema.org/Article">
          <StyledVideoTestimonialHeader>
            <h1 itemProp="headline">Group Class Sessions</h1>
          </StyledVideoTestimonialHeader>
          <EventListSection style={{ "padding": "0" }}>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} >
              <TabList>
                <Tab>Session 1</Tab>
                <Tab>Session 2</Tab>
              </TabList>
              <TabPanel>
                {/* Session 1 */}
                <StyledVideoTestimonialVideo className="replay">

                  {isWatchFullVersionOpen ? <ReactVideoPlayer
                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-mhg/group-class/MHG-Class-Session-1.mp4"
                    title="MHG Class Session 1"
                    playing={true}
                    download={true}
                  />
                    :
                    <ReactAudioPlayer
                      autoPlay={false}
                      audioURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-mhg/group-class/MHG-Class-Session-1.mp3"
                      onPlay={e => analyticClickEvent('Click', 'Audio', 'Play: MHG Class Session 1')}
                      title="MHG Class Session 1"
                    />
                  }

                  {!isWatchFullVersionOpen ?
                    <>
                      <StyledLinkButton className="download-audio" inverse={true} href="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-mhg/group-class/MHG-Class-Session-1.mp3" download onClick={() => analyticClickEvent('click',"Download",'MHG Session 1')}>
                        <svg width="27" height="22" xmlns="http://www.w3.org/2000/svg"><g fill="#000" fill-rule="evenodd"><path d="M0 8h2.2v11H0zM12 0h2.2v14H12z" /><path d="m5.987 8.242 1.555-1.555 7.071 7.07-1.555 1.556z" /><path d="m18.558 6.687 1.555 1.555-7.07 7.071-1.556-1.555zM24 8h2.2v11H24zM0 19h26.2v2.2H0z" /></g></svg>
                        Download audio</StyledLinkButton>
                      <StyledButton className="watchlisten-more" onClick={() => toggleTestimonials(true)}>Watch the replay</StyledButton>
                    </>
                    :
                    <StyledButton className="watchlisten-more" onClick={() => toggleTestimonials(false)}>Listen to the replay</StyledButton>
                  }
                </StyledVideoTestimonialVideo>
              </TabPanel>

              <TabPanel>
                {/* Session 2 */}
                <StyledVideoTestimonialVideo className="replay">

                  {isWatchFullVersionOpen ? <ReactVideoPlayer
                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-mhg/group-class/MHG-Class-Session-2.mp4"
                    title="MHG Class Session 2"
                    playing={true}
                    download={true}
                  />
                    :
                    <ReactAudioPlayer
                      autoPlay={false}
                      audioURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-mhg/group-class/MHG-Class-Session-2.mp3"
                      onPlay={e => analyticClickEvent('Click', 'Audio', 'Play: MHG Class Session 2')}
                      title="MHG Class Session 2"
                    />
                  }

                  {!isWatchFullVersionOpen ?
                    <>
                      <StyledLinkButton className="download-audio" inverse={true} href="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-mhg/group-class/MHG-Class-Session-2.mp3" download onClick={() => analyticClickEvent('click',"Download",'MHG Session 2')}>
                        <svg width="27" height="22" xmlns="http://www.w3.org/2000/svg"><g fill="#000" fill-rule="evenodd"><path d="M0 8h2.2v11H0zM12 0h2.2v14H12z" /><path d="m5.987 8.242 1.555-1.555 7.071 7.07-1.555 1.556z" /><path d="m18.558 6.687 1.555 1.555-7.07 7.071-1.556-1.555zM24 8h2.2v11H24zM0 19h26.2v2.2H0z" /></g></svg>
                        Download audio</StyledLinkButton>
                      <StyledButton className="watchlisten-more" onClick={() => toggleTestimonials(true)}>Watch the replay</StyledButton>
                    </>
                    :
                    <StyledButton className="watchlisten-more" onClick={() => toggleTestimonials(false)}>Listen to the replay</StyledButton>
                  }
                </StyledVideoTestimonialVideo>
              </TabPanel>
              
            </Tabs>
          </EventListSection>
        </StyledVideoTestimonialArticle>



        <Section>
          <Heading level={1} className="hidden"> Audio Programs &amp; Meditations</Heading>
          <ProductList posts={products} />
        </Section>

      </LayoutNoStripe>
    </>
  )
}

export default FhtjMhgClassSessions

export const pageQuery = graphql`
      query {
        site {
        siteMetadata {
        title
      }
    }

      wpPage(slug: {eq: "homepage" }) {
        title
      slug
      content
      testimonials {
        blockquote {
        citeLocation
          citationName
      quote
        }
      testimonialHeading
      testimonialLede
      }
    }

      aboutKarina: wpPage(slug: {eq: "about" }) {
        aboutKarina {
        aboutKarina
      }
      featuredImage {
        node {
        localFile {
        childImageSharp {
        gatsbyImageData
      }
          }
        }
      }
    }
      

      allWpPost(sort: {fields: [date], order: DESC } limit: 5) {
        nodes {
        excerpt
        uri
      date(formatString: "MMMM DD, YYYY")
      title
      isSticky
      categories {
        nodes {
        slug
      }
        }
      featuredImage {
        node {
        altText
            localFile {
        childImageSharp {
        gatsbyImageData(
          width: 975
      quality: 100
      placeholder: TRACED_SVG
      breakpoints: [320, 720, 1024, 1600]
      )
              }
            }
          }
        }
      }
    }

    allWpProduct(sort: {order: ASC, fields: menuOrder}, filter: {title: {ne: "Permission Bonus"}}) {
      edges {
        node {
          title
          uri
          id
          menuOrder
          excerpt
          productsTag {
            nodes {
              name
              slug
            }
          }
          productData {
            linkToAmazon
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            pricefullusd
            pricesaleusd
            stripefullpriceusd
            stripesalepriceusd
            hidesale
            hideonarchive 
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
    #   allWpProduct(limit: 3) {
    #     edges {
    #     node {
    #     title
    #       uri
    #   excerpt
    #   productsTag {
    #     nodes {
    #     name
    #   }
    #       }
    #   productData {
    #     pricefull
    #         pricesale
    #   stripefullprice
    #   stripesaleprice
    #   hidesale
    #       }
    #   featuredImage {
    #     node {
    #     localFile {
    #     childImageSharp {
    #     gatsbyImageData
    #   }
    #           }
    #         }
    #       }
    #     }
    #   }
    # }
}
      `
